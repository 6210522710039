import React from "react";
import Header from "../../components/MainHeader/Header";
import Home from "../../components/HomePage/HomePage";

const HomePage: React.FC = () => {
  return (
    <div>
      <Header />
      <Home />
    </div>
  );
};

export default HomePage;

import React, { useEffect, useRef, useState, useContext } from "react";
import SecondaryHeader from "../SecondaryHeader/SecondaryHeader";
import MenuBar from "../MenuBar/MenuBar";
import user from "../../assets/1.jpg";
import { HttpGet, HttpPost } from "../../providers/HttpClient";
import LoadingOverlay from "react-loading-overlay-ts";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Alert from "../alertComponent/alertComponent";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const Overtime: React.FC = () => {
  const navigate = useNavigate();
  const [hours, setHours] = useState<any>(8);
  const [memberId, setMemberId] = useState(null);
  const [workLocation, setWorkLocaion] = useState("");
  const noteRef = useRef<HTMLTextAreaElement>(null);
  const [isLoading, setLoading] = useState(false);
  const [memberList, setMemberList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [status, setStatus] = useState(
    JSON.parse(localStorage.getItem("checkinInfo"))
  );
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState(false);
  const [isDisabled, setDisabled] = useState(true);

  const alert = (message, type) => {
    setOpen(true);
    setAlertMessage(message);
    setAlertType(type);
  };
  const closeAction = async () => {
    setOpen(false);
    navigate("/home");
  };
  const manageOvertime = async () => {
    let data = {
      hours: hours,
      idMember: memberId,
      note: noteRef.current?.value,
      // shiftId: status.shiftId,
      workLocationId: workLocation,
    };
    setLoading(true);
    HttpPost(`member/overtime`, data)
      .then((res) => {
        alert("Lembur Backup Telah Dikirim !", true);
        setLoading(false);
        // navigate("/home");
      })
      .catch((err) => {
        setLoading(false);
        alert("Lembur Backup Gagal Dikirim !", false);
      });
  };

  const getMemberList = async (id) => {
    setLoading(true);
    HttpGet(`member/combo/member?workLocation=${id}`)
      .then((res: any) => {
        setDisabled(false);
        setMemberList(res.data);
        // getLocationList(res.data._id);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getLocationList = async () => {
    setLoading(true);
    HttpGet(`member/profile/combo_work_location`)
      .then((res: any) => {
        setLocationList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    // getMemberList();
    getLocationList();
  }, []);
  return (
    <>
      <Alert
        type={alertType}
        isOpen={open}
        closeAction={closeAction}
        message={alertMessage}
      />
      <LoadingOverlay
        className="h-full"
        active={isLoading}
        spinner
        text="Loading..."
      >
        <header className="flex items-center justify-between px-4 py-1">
          <h1 className="text-base font-bold">Lembur Backup</h1>
        </header>
        {/* <SecondaryHeader title="Lembur" pathname="/home" /> */}
        <section id="main" className="bg-main h-full px-8 pb-24 -mt-28 z-50">
          <div className="mt-32 z-100 z-30 mb-4">
            <div className="mb-8">
              <label htmlFor="location" className="block text-gray-700">
                Pilihan Shift
              </label>
              <select
                value={hours}
                onChange={(event) => {
                  setHours(event.target.value);
                }}
                className="bg-white border border-gray-300 rounded-full px-4 py-2 w-full"
              >
                <option disabled>Pilih Shift :</option>
                <option value={12}>12 Jam</option>
                <option value={8}>8 Jam</option>
              </select>
            </div>
            <div className="mb-8">
              <label htmlFor="location" className="block text-gray-700">
                Lokasi Kerja
              </label>
              <select
                value={workLocation}
                onChange={(event) => {
                  getMemberList(event.target.value);
                  setWorkLocaion(event.target.value);
                }}
                className="bg-white border border-gray-300 rounded-full px-4 py-2 w-full"
              >
                <option></option>
                {locationList.map((el: any, index: any) => (
                  <option key={index} value={el._id}>
                    {el.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-8">
              <label htmlFor="backup" className="block text-gray-700">
                Anggota Yang di Backup
              </label>
              <select
                disabled={!workLocation}
                value={memberId}
                onChange={(event) => {
                  setMemberId(event.target.value);
                }}
                className={`${
                  isDisabled ? "bg-gray-200" : "bg-white"
                }  border border-gray-300 rounded-full px-4 py-2 w-full`}
              >
                <option></option>
                {memberList.map((el: any, index: any) => (
                  <option key={index} value={el._id}>
                    {el.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label htmlFor="note" className="block text-gray-700">
                Keterangan
              </label>
              <textarea
                id="note"
                className="border border-gray-300 rounded-lg px-4 py-2 w-full"
                ref={noteRef}
              />
            </div>
          </div>
          <div className="flex justify-end">
            <div
              onClick={() => manageOvertime()}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full justify-center grid w-28"
            >
              Kirim
            </div>
          </div>
        </section>
        <MenuBar></MenuBar>
      </LoadingOverlay>
    </>
  );
};

export default Overtime;

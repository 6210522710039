import React from "react";
import Event from "../../components/eventComponent/eventComponent";
// import SecondaryHeader from "../../components/SecondaryHeader/SecondaryHeader";

const HistoryPage: React.FC = () => {
  return (
    <div>
      {/* <SecondaryHeader title="Event" pathname="/home" /> */}
      <Event />
    </div>
  );
};

export default HistoryPage;

import React from "react";
import SecondaryHeader from "../../components/SecondaryHeader/SecondaryHeader";
import QRScanComponent from "../../components/QRScanComponent/QRScan";

const Profile: React.FC = () => {
  return (
    <div>
      <SecondaryHeader title="Absen" pathname="/home" />
      <QRScanComponent />
    </div>
  );
};

export default Profile;

import React from "react";
import ChangePasswordFirstLogin from "../../components/ChangePasswordFirstLoginComponent/ChangePasswordFirstLoginComponent";
// import SecondaryHeader from "../../components/SecondaryHeader/SecondaryHeader";

const ChangePasswordFirstLoginPage: React.FC = () => {
  return (
    <div>
      {/* <SecondaryHeader title="Event" pathname="/home" /> */}
      <ChangePasswordFirstLogin />
    </div>
  );
};

export default ChangePasswordFirstLoginPage;

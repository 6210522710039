import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage/LoginPage";
import HomePage from "./pages/HomePage/HomePage";
import HistoryPage from "./pages/HistoryPage/HistoryPage";
import Profile from "./pages/ProfilePage/ProfilePage";
import QRScanner from "./pages/QRScanPage/QRScan";
import Overtime from "./components/OvertimeComponent/OvertimeComponent";
import Patrol from "./components/PatrolComponent/PatrolComponent";
import Event from "./pages/EventPage/EventPage";
import ChangePasswordFirstLoginPage from "./pages/ChangePasswordFirstLoginPage/ChangePasswordFirstLoginPage";
import CheckOut from "./pages/CheckoutPage/CheckOut";
import SelfieCheckinPage from "./pages/SelfieCheckinPage/SelfieCheckin";
import SelfieCheckOutPage from "./components/SelfieCheckOut/SelfieCheckOut";
import ShiftChoosePage from "./pages/ShiftChoose/ShiftChoose";
import Overtime2 from "./components/Overtime2Component/OvertimeComponent";
import OvertimeList from "./components/Overtime2Component/OvertimeListComponent";

const App: React.FC = () => {
  return (
    <Router>
      <div className="">
        <Routes>
          <Route path="/:initial" element={<LoginPage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/history" element={<HistoryPage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/home/checkin/:id" element={<QRScanner />} />
          <Route path="/home/overtime-form" element={<Overtime />} />
          <Route path="/home/patrol-form" element={<Patrol />} />
          <Route path="/home/event/:id" element={<Event />} />
          <Route
            path="/home/change-first-password"
            element={<ChangePasswordFirstLoginPage />}
          />
          <Route path="/home/checkout" element={<CheckOut />}></Route>
          <Route
            path="/home/selfie-checkin"
            element={<SelfieCheckinPage />}
          ></Route>
          <Route
            path="/home/selfie-checkout"
            element={<SelfieCheckOutPage />}
          ></Route>
          <Route
            path="/home/shift-choose"
            element={<ShiftChoosePage />}
          ></Route>
          <Route path="/home/overtimes-form" element={<Overtime2 />}></Route>
          <Route path="/home/overtimes-list" element={<OvertimeList />}></Route>
        </Routes>
      </div>
    </Router>
  );
};

export default App;

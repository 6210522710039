import React from "react";
import History from "../../components/HistoryPage/HistoryPage";
// import SecondaryHeader from "../../components/SecondaryHeader/SecondaryHeader";

const HistoryPage: React.FC = () => {
  return (
    <div>
      {/* <SecondaryHeader title="History" pathname="/home" /> */}
      <History />
    </div>
  );
};

export default HistoryPage;

export const error400 = (respon) => {
  console.log("reserer", respon);
  if (respon === "ALREADY_CHECKED_IN") {
    return "Anda Sudah Absen Masuk";
  } else if (respon === "NOT_YET_CHECKED_IN") {
    return "Anda Belum Absen Masuk";
  } else if (respon === "NOT_YET_CHECKED_OUT") {
    return "Anda Belum Absen Keluar";
  } else if (respon === "SHIFT_EXPIRED") {
    return "Shift Kedaluwarsa";
  } else if (
    respon ===
    "CURRENT_TIME_IS_NOT_BETWEEN_SHIFT_CHECK_IN_TIME_AND_CHECK_OUT_TIME"
  ) {
    return "Waktu Saat Ini Tidak Sesuai Dengan Waktu Shift";
  } else if (
    respon ===
    "CURRENT_TIME_IS_NOT_BETWEEN_SHIFT_START_OPEN_TIME_AND_CHECK_OUT_TIME"
  ) {
    return "Waktu Saat Ini Bukan Antara Waktu Shift Mulai Dan Waktu Check Out";
  } else if (respon === "MEMBER_CANNOT_BACK_UP_SELF") {
    return "Anda Tidak Bisa Membackup Diri Sendiri";
  } else if (respon === "ALREADY_ATTEND_EVENT") {
    return "Anda Sudah Konfirmasi Event";
  } else if (respon === "EVENT_MUST_BE_TODAY") {
    return "Event Harus Hari Ini";
  } else if (respon === "WRONG_USERNAME_OR_PASSWORD") {
    return "Username/Password Salah";
  } else {
    return respon;
  }
};

export const error401 = (respon) => {
  if (respon === "WRONG_USERNAME_OR_PASSWORD") {
    return "Username/Password Salah";
  } else if (respon === "INVALID_TOKEN") {
    return "Token Tidak Sesuai";
  } else {
    return respon;
  }
};

export const error404 = (respon) => {
  if (respon === "MEMBER_NOT_FOUND") {
    return "Member Tidak Ditemukan";
  } else if (respon === "LOCATION_NOT_FOUND") {
    return "Lokasi Tidak Ditemukan";
  } else if (respon === "OVERTIME_NOT_FOUND") {
    return "Lembur Tidak Ditemukan";
  } else if (respon === "PATROL_NOT_FOUND") {
    return "Patroli Tidak Ditemukan";
  } else if (respon === "SHIFT_NOT_FOUND") {
    return "Shift Tidak Ditemukan";
  } else if (respon === "ATTENDANCE_NOT_FOUND") {
    return "Member Tidak Ditemukan";
  } else if (respon === "EVENT_NOT_FOUND") {
    return "Event Tidak Ditemukan";
  } else {
    return respon;
  }
};

import React, { useEffect, useState } from "react";
import user from "../../assets/1.jpg";
import qrScan from "../../assets/svg/qrScan.svg";
import clock from "../../assets/svg/clock.svg";
import patrol from "../../assets/svg/speaker.svg";
import checkout from "../../assets/svg/checkout.svg";
import checkin from "../../assets/svg/checkin.svg";
import signIn from "../../assets/svg/signIn.svg";
import signOut from "../../assets/svg/signOut.svg";
import SecondaryHeader from "../SecondaryHeader/SecondaryHeader";
import MenuBar from "../MenuBar/MenuBar";
import { HttpGet } from "../../providers/HttpClient";
import moment from "moment";
import "./historyPage.css";

const History: React.FC = () => {
  moment.locale("es");
  const [historyData, setHistory] = useState<any>([]);
  const [page, setPage] = useState<any>(0);
  const [size, setSize] = useState<any>(10);
  const [userCategory, setuUserCategory] = useState(
    JSON.parse(localStorage.getItem("tokenInfo")).category
  );
  const [attendanceType, setAttendanceType] = useState<any>(
    JSON.parse(localStorage.getItem("companyProfile")).attendanceType
  );

  const GetHistory = async () => {
    HttpGet(
      `member/attendance?size=${size}&page=${page}&sort=createTime&dir=-1`
    ).then((res: any) => {
      setHistory(res.data.content);
    });
  };

  const GetHistorySelfie = async () => {
    HttpGet(
      `member/attendance_by_photo?size=${size}&page=${page}&sort=updateTime&dir=-1`
    ).then((res: any) => {
      setHistory(res.data.content);
    });
  };

  useEffect(() => {
    if (attendanceType === "SELFIE") {
      GetHistorySelfie();
    } else {
      GetHistory();
    }
  }, []);
  return (
    <>
      <SecondaryHeader title="Riwayat Kehadiran" pathname="home" />
      <div className="bg-main h-full">
        {/* <section id="main" className="relative px-5 -mt-28 z-50"> */}
        <div className="mt-4 z-100 z-30 mb-4 pb-24 px-5">
          {/* <div className=""> */}

          <p className="mb-4 text-center font-header font-bold">
            Riwayat Absen Minggu Ini
          </p>
          {/* </div> */}
          {historyData.length
            ? historyData.map((prop: any, index: any) => {
                return (
                  <div className="rounded-2xl w-full bg-gray-50 p-3 shadow-md mb-4">
                    <p className="font-bold font-size ml-3">
                      {moment(prop.createTime).format("dddd, DD MMMM YYYY")}
                    </p>
                    <div className="flex flex-row">
                      <img src={signIn} alt="qr" className="h-10 p-2" />
                      <div className="rounded-full bg-white shadow-md h-fit my-auto w-36">
                        <p className="text-content p-1 pr-10 m-1 font-bold">
                          {prop.checkInTime
                            ? moment(prop.checkInTime).format("LT")
                            : "-"}{" "}
                          WIB
                        </p>
                      </div>
                      {prop.status === "ON_TIME" ? (
                        <div
                          style={{ left: "10rem" }}
                          className="rounded-full bg-green-500 shadow-md h-fit absolute m-1"
                        >
                          <p className="text-content text-white p-1 m-1 font-bold">
                            Tepat Waktu
                          </p>
                        </div>
                      ) : prop.status === "LATE" ? (
                        <div
                          style={{ left: "10rem" }}
                          className="rounded-full bg-red-500 shadow-md h-fit absolute m-1"
                        >
                          <p className="text-content text-white p-1 m-1 flex justify-center font-bold">
                            Terlambat
                          </p>
                        </div>
                      ) : (
                        <div
                          style={{ left: "10rem" }}
                          className="rounded-full bg-orange-500 shadow-md h-fit absolute m-1"
                        >
                          <p className="text-content text-white p-1 m-1 font-bold">
                            Toleransi
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="flex flex-row">
                      <img src={signOut} alt="qr" className="h-10 p-2" />
                      <div className="rounded-full bg-white shadow-md h-fit my-auto">
                        <p className="text-content p-1 m-1 font-bold">
                          {prop.checkOutTime
                            ? moment(prop.checkOutTime).format("LT")
                            : "-"}{" "}
                          WIB
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
        {/* </section> */}
        <MenuBar label={"Riwayat"}></MenuBar>
      </div>
    </>
  );
};

export default History;

import React, { useState, useCallback, useRef, useEffect } from "react";
import QrScanner from "qr-scanner";
import {
  HttpLogin,
  HttpCheckToken,
  HttpPost,
  HttpGet,
  HttpPut,
} from "../../providers/HttpClient";
import { useLocation, useNavigate } from "react-router-dom";
import MenuBar from "../MenuBar/MenuBar";
import Alert from "../alertComponent/alertComponent";
import "./QRscanner.css";
import Axios from "axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { toast } from "react-toastify";

const Checkout = () => {
  const [btnScan, setBtnScan] = useState(true);
  const [data, setData] = useState<any>({});
  const [visible, setVisible] = useState(false);
  let location: any = useLocation();
  let stopScan = false;
  const navigate = useNavigate();
  const [erorMessage, setErorMessage] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [address, setAddress] = useState("");
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState(false);
  const [isScanDone, setIsScanDone] = useState(false);
  const [isGetAddress, setIsGetAddress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const closeAction = async () => {
    setOpen(false);
    navigate("/home");
  };

  const alert = (message, type) => {
    setOpen(true);
    setAlertMessage(message);
    setAlertType(type);
  };
  const scanNow = async (isScan: any) => {
    setBtnScan(isScan);
    setIsLoading(true);

    if (isScan === false) stopScan = true;

    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    await new Promise((r) => setTimeout(r, 3000));
    const videoElement: any = document.getElementById("scanView");
    const scanner = new QrScanner(
      videoElement,
      (result: any) => {
        // setTimeout(() => {
        getData(result);
        scanner.stop();
        scanner.destroy();
        // }, 3000); // Add delay here
      },
      {
        onDecodeError: (error) => {},
        maxScansPerSecond: 1,
        highlightScanRegion: true,
        highlightCodeOutline: true,
        returnDetailedScanResult: true,
      }
    );
    await scanner.start();
    while (stopScan === false) await new Promise((r) => setTimeout(r, 3000));
    scanner.stop();
    scanner.destroy();
  };

  const getData = (result: any) => {
    let body = {
      lat: latitude,
      lon: longitude,
      address: address,
    };
    //   dispatch(setLoading(true));
    HttpPut(`member/attendance/check_out/v2/${result.data}`, body)
      .then((res: any) => {
        setIsScanDone(true);
        alert("Checkout Berhasil", true);
        localStorage.setItem("checkinInfo", JSON.stringify(res.data));
      })
      .catch((err) => {
        setIsScanDone(true);
        alert("Checkout Gagal", false);
        let counter: any = sessionStorage.getItem("scanCounter");
      });
  };

  const getAddress = async (latitude, longitude) => {
    setIsLoading(true);
    Axios.get(
      `https://geocode.maps.co/reverse?lat=${latitude}&lon=${longitude}&api_key=659ceae1d022c624309563ymk7c7207`
    )
      .then((res: any) => {
        setAddress(res.data.display_name);
        setIsGetAddress(true);
        // setPowered(res.data.powered_by);
      })
      .catch((error) => {
        setIsGetAddress(true);
      });
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          getAddress(position.coords.latitude, position.coords.longitude);
        },
        function (error) {
          toast.error("Error getting geolocation");
        }
      );
    } else {
      toast.error("Geolocation is not available in this browser.");
    }
  }, []);
  useEffect(() => {
    if (!isScanDone && isGetAddress) {
      scanNow(true);
    }
  }, [latitude, longitude, address, isGetAddress]);

  return (
    <>
      <div className="bg-main h-full mb-12">
        {/* <LoadingOverlay
          className="h-full"
          active={isLoading}
          spinner
          text="Loading..."
        > */}
        <Alert
          type={alertType}
          isOpen={open}
          closeAction={closeAction}
          message={alertMessage}
        />
        <div className="pb-28 ">
          <div hidden={isLoading == false}>
            <Skeleton
              containerClassName="qr-scanner-container"
              baseColor="#000000"
              highlightColor="#363434"
              className="qr-scanner-video"
              style={{ borderRadius: 12 }}
            />
          </div>
          <div hidden={isLoading == true} className="qr-scanner-container">
            <video
              id="scanView"
              // style={{ height: 500 }}
              className="qr-scanner-video"
            ></video>
          </div>
          <div className="flex justify-center mt-2 mb-10">
            <p>Scan QR Code untuk absen keluar</p>{" "}
          </div>{" "}
          <div className="flex justify-center bottom-0">
            {" "}
            <p className="font-light text-sm">
              powered by: <a href="https://geocode.maps.co/">https://maps.co</a>{" "}
            </p>{" "}
          </div>
        </div>
        {/* </LoadingOverlay> */}

        <MenuBar label={""}></MenuBar>
      </div>
    </>
  );
};
export default Checkout;

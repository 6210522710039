import React, { useState } from "react";
import logo from "../../assets/LOGO LUJ.png";
import userIcon from "../../assets/svg/user.svg";
import moment from "moment";
import "../../App.css";
const Header = () => {
  moment().locale("id");
  const [date, setDate] = useState<any>(new Date());
  const [companyProfile, setCompanyProfile] = useState<any>(
    JSON.parse(localStorage.getItem("companyProfile"))
  );
  return (
    <header
      style={{ backgroundColor: `${companyProfile?.color}` }}
      className="flex items-center justify-between px-4 pt-4 "
    >
      {/* Logo aplikasi */}
      <div className="flex">
        <img
          src={companyProfile?.logo}
          alt="Logo"
          className="h-12 mr-4 rounded-full"
        />
        <p className="flex items-center text-white font-size">
          {companyProfile?.name}
        </p>
      </div>

      {/* Icon pengguna */}
      <div className="flex">
        <p className="flex items-center text-white font-size">
          {moment(new Date()).locale("id").format("DD MMMM YYYY")}
        </p>
      </div>
    </header>
  );
};

export default Header;

import { FC } from "react";
import { useNavigate } from "react-router-dom";
import back from "../../assets/svg/back.svg";
interface SecondaryHeaderProps {
  title?: string;
  pathname?: string;
}
const SecondaryHeader: FC<SecondaryHeaderProps> = (props): JSX.Element => {
  const history = useNavigate();

  //   const handleGoBack = () => {
  //     history(props.pathname);
  //   };
  return (
    <>
      <header className="flex items-center justify-between px-4 py-1">
        {/* Logo aplikasi */}
        {/* <button
          className="hover:text-blue-700 focus:outline-none"
          onClick={() => history(`${props.pathname}`)}
        >
          <img src={back} alt="qr" className="h-14 p-2" />
        </button> */}

        {/* <h1 className="text-base">{props.title}</h1> */}
      </header>
    </>
  );
};

export default SecondaryHeader;

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RouterConfig from "./router-config";
import "./App.css";
import moment from "moment";
import { ToastContainer, Slide, Zoom, Flip, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "moment/locale/id";
moment.locale("id");

const App: React.FC = () => {
  // const companyProfile = localStorage.getItem("companyProfile");
  // const parsedCompanyProfile = companyProfile
  //   ? JSON.parse(companyProfile)
  //   : null;
  const [company, setCompany] = useState<any>(
    JSON.parse(localStorage.getItem("companyProfile"))
  );
  function getFaviconEl() {
    return document.getElementById("favicon") as HTMLLinkElement | null;
  }

  useEffect(() => {
    const favicon: any | null = getFaviconEl();
    favicon.href = company?.logo != null ? company?.logo : null;
  }, [company]);
  return (
    <div className="bg-main h-screen">
      <ToastContainer
        theme="light"
        position="top-center"
        newestOnTop
        transition={Flip}
      />
      <RouterConfig />
    </div>
  );
};

export default App;

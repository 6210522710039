import { FC, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./menu-bar.css";
import home from "../../assets/svg/home.svg";
import userMenu from "../../assets/svg/userMenu.svg";
import homeLine from "../../assets/svg/homeLine.svg";
import userLine from "../../assets/svg/userLine.svg";
import history from "../../assets/svg/history-icon.svg";
import historyInactive from "../../assets/svg/history-line-icon.svg";
import "react-multi-carousel/lib/styles.css";

interface BarMenu {
  label?: String;
}
const MenuBar: FC<BarMenu> = (props): JSX.Element => {
  const [listTabs, setlistTabs] = useState<any[]>([]);
  const tabs = [
    {
      route: "/home",
      activeIcon: home,
      icon: homeLine,
      label: "Beranda",
      active: false,
    },
    {
      route: "/history",
      activeIcon: history,
      icon: historyInactive,
      label: "Riwayat",
      active: false,
    },
    {
      route: "/profile",
      activeIcon: userMenu,
      icon: userLine,
      label: "Profil",
      active: false,
    },
  ];
  const [companyProfile, setCompanyProfile] = useState<any>(
    JSON.parse(localStorage.getItem("companyProfile"))
  );
  const activeNav =
    "grow flex flex-row justify-between gap-2 px-3 py-2.5 m-0 rounded-full";

  const loadData = async () => {
    const item = tabs.find((item) => item.label === props.label);
    if (item != null) {
      item.active = true;
    }

    setlistTabs(tabs);
  };

  useEffect(() => {
    loadData();
  }, [props]);
  return (
    <section className="bar fixed z-50 bottom-0 w-full bg-main px-4 py-4">
      <div
        style={{ backgroundColor: companyProfile?.color }}
        className="relative rounded-2xl"
      >
        {/* <span
            className={`absolute bg-psp-hover w-28 rounded-full h-12 z-1 top-2 ${
              props.tab == "Info"
                ? "transition-transform sca"
                : "translate-x-0"
            }`}
          ></span> */}

        <ul className="menus m-0 p-0 flex flex-row gap-1 justify-between items-center z-50 h-16">
          {listTabs.map((item) =>
            item.active == true ? (
              <Link to={item.route} className="no-underline">
                <li className={activeNav}>
                  {/* <div
                    // style={{ backgroundColor: customApp?.app_accent_color }}
                    className={`relative m-0 flex flex-row items-center w-fit z-50 overflow-hidden anima bg-red-900 `}
                  >
                    <img
                      src={item.activeIcon}
                      width={30}
                      className="anima-icon"
                    />
                    <span
                      style={{ display: "flex" }}
                      className="text-white anima-text "
                    >
                      {item.label}
                    </span>
                  </div> */}
                  <div
                    className="img"
                    // style={{ backgroundColor: customApp?.app_accent_color }}
                    // className={`relative m-0 flex-row items-center w-fit overflow-hidden anima bg-red-700`}
                  >
                    <img
                      src={item.activeIcon}
                      width={30}
                      className="img-active mx-auto"
                    />
                    <span className="img-text text-white anima-text text-sm">
                      {item.label}
                    </span>
                  </div>
                </li>
              </Link>
            ) : (
              <Link to={item.route} className="no-underline z-50">
                <li className="flex-0 ">
                  <div className="img py-2 px-3">
                    <img className="img-inactive" src={item.icon} width={30} />
                  </div>
                </li>
              </Link>
            )
          )}
        </ul>
      </div>
    </section>
  );
};

export default MenuBar;

import React, { Fragment, useEffect, useRef, useState } from "react";
import SecondaryHeader from "../SecondaryHeader/SecondaryHeader";
import MenuBar from "../MenuBar/MenuBar";
import user from "../../assets/1.jpg";
import {
  HttpGet,
  HttpPost,
  HttpPostFile,
  HttpPut,
} from "../../providers/HttpClient";
import { toast } from "react-toastify";
import camera from "../../assets/svg/camera.svg";
import Config from "../../config/Config";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";
import EyeOffIcon from "../../assets/svg/eye-off.svg";
import EyeIcon from "../../assets/svg/eye.svg";
// import "./profilePage.css";

const ChangePasswordFirstLogin: React.FC = () => {
  const env = Config.BaseUrlDev;
  const navigate = useNavigate();
  const [loading, setLoading] = useState<any>(false);
  const [isDisabled, setDisabled] = useState<any>(true);
  const [password, setPassword] = useState<any>("");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [typePassword, setTypePassword] = useState("password");

  const funTypePassword = async () => {
    if (typePassword == "text") {
      setTypePassword("password");
    } else {
      setTypePassword("text");
    }
  };

  const updatePassword = async () => {
    if (password == "") return toast.error("Password Baru Harap Diisi");

    let data = {
      password: password,
    };
    setLoading(true);
    HttpPut(`member/account/change_password_first_login`, data)
      .then((res) => {
        toast.success("Berhasil merubah password");
        setLoading(false);
        navigate("/home");
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const handleClick = () => {
    inputRef?.current?.click();
  };

  useEffect(() => {}, []);
  return (
    <div className="bg-main h-full">
      <SecondaryHeader title="Profil" pathname="home" />
      <LoadingOverlay
        className="h-full"
        active={loading}
        spinner
        text="Loading..."
      >
        <div className="px-6 mt-20 mb-auto py-28">
          <div className="flex flex-col flex-gap">
            <div className="mb-4">
              <label htmlFor="username" className="block text-gray-700">
                Password Baru
              </label>
              <div className="relative w-full flex">
                <input
                  type={typePassword}
                  id="name"
                  className="border border-gray-300 rounded-full px-4 p-2 w-full font-size"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  // ref={usernameRef}
                />
                <img
                  src={typePassword != "text" ? EyeOffIcon : EyeIcon}
                  className="font-bold text-black absolute z-10 top-2 right-5"
                  onClick={() => funTypePassword()}
                />
              </div>
            </div>
            <button
              // style={{ backgroundColor: customApp?.app_secondary_accent_color }}
              onClick={() => {
                updatePassword();
              }}
              className="py-4 bg-orange-600 mt-3 text-white font-medium text-sm items-center justify-center bg-yellow w-full flex flex-row rounded-lg gap-3 font-size"
            >
              Ubah Password
            </button>
          </div>
        </div>
        {/* <MenuBar label={"Profil"}></MenuBar> */}
      </LoadingOverlay>
    </div>
  );
};

export default ChangePasswordFirstLogin;

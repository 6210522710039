import React, { Fragment, useEffect, useRef, useState } from "react";
import SecondaryHeader from "../SecondaryHeader/SecondaryHeader";
import MenuBar from "../MenuBar/MenuBar";
import user from "../../assets/1.jpg";
import {
  HttpGet,
  HttpPost,
  HttpPostFile,
  HttpPut,
} from "../../providers/HttpClient";
import { toast } from "react-toastify";
import camera from "../../assets/svg/camera.svg";
import Config from "../../config/Config";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";
import "./profilePage.css";

const Profile: React.FC = () => {
  const env = Config.BaseUrlDev;
  const navigate = useNavigate();
  const [loading, setLoading] = useState<any>(false);
  const [profile, setProfile] = useState<any>({});
  const [isDisabled, setDisabled] = useState<any>(true);
  const [name, setName] = useState<any>("");
  const [birthDate, setBirthDate] = useState<any>("");
  const [birthPlace, setBirthPlace] = useState<any>("");
  const [address, setAddress] = useState<any>("");
  const [workLocation, setWorkLocation] = useState<any>("");
  const [noId, setNoId] = useState<any>("");
  const [profilePicture, setProfilePicture] = useState<any>("");
  const [phoneNumber, setPhoneNumber] = useState<any>("");
  const [locations, setLocations] = useState<any>([]);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [imageName, setImageName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [initial, setInitial] = useState<any>(
    JSON.parse(localStorage.getItem("companyProfile"))
  );
  // const initial = localStorage.getItem("companyProfile");

  const closeActionLogout = async () => {
    setIsOpen(false);
  };
  const closeAction = async () => {
    setOpen(false);
  };
  const logout = () => {
    localStorage.clear();
    navigate(`/${initial.initial}`);
  };

  const GetProfile = async () => {
    HttpGet(`member/profile`)
      .then((res: any) => {
        localStorage.setItem("profile", JSON.stringify(res.data));
        setProfile(res.data);
        setName(res.data.name);
        setBirthDate(res.data.birthDate);
        setBirthPlace(res.data.birthPlace);
        setAddress(res.data.address);
        setWorkLocation(res.data.workLocation);
        setNoId(res.data.noId);
        setImageName(res.data.profilePicture);
        setPhoneNumber(res.data.phoneNumber);
      })
      .catch((err) => {
        // setLoading(false);
      });
  };

  const getWorkLocation = async () => {
    HttpGet(`member/profile/combo_work_location`).then((res: any) => {
      setLocations(res.data);
    });
  };
  const updateProfile = async () => {
    let data = {
      name: name,
      birthDate: birthDate,
      birthPlace: birthPlace,
      address: address,
      workLocation: workLocation,
      noId: noId,
      profilePicture: imageName,
      phoneNumber: phoneNumber,
    };
    setLoading(true);
    HttpPut(`member/profile`, data)
      .then((res) => {
        toast.success("Berhasil merubah data");
        setDisabled(true);
        setLoading(false);
        GetProfile();
        // navigate("/home");
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const handleClick = () => {
    inputRef?.current?.click();
  };
  const uploadChoosenPhoto = async (event) => {
    let formData = new FormData();
    formData.append("image", event.target.files[0]);

    HttpPostFile(`member/images?feature=patrol`, formData).then((res: any) => {
      setImageName(res.data.imageName);
    });
  };
  useEffect(() => {
    GetProfile();
    getWorkLocation();
  }, []);
  return (
    <div className="bg-main h-full">
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeActionLogout}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 flex justify-center"
                  >
                    Keluar?
                  </Dialog.Title>
                  <div className="mt-2 flex justify-center">
                    <p className="text-sm text-gray-500">Apakah anda yakin?</p>
                  </div>

                  <div className="mt-4 flex justify-center gap-2">
                    <button
                      type="button"
                      className="active:bg-psp-hover active:text-white inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => logout()}
                    >
                      Ya
                    </button>
                    <button
                      type="button"
                      className="active:bg-zinc-100 inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={() => closeActionLogout()}
                    >
                      Tidak
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <SecondaryHeader title="Profil" pathname="home" />
      <LoadingOverlay
        className="h-full"
        active={loading}
        spinner
        text="Loading..."
      >
        <div className="pb-28 px-6">
          <div className="flex justify-end">
            <div
              className="px-3 text-white rounded-full button-padding font-size"
              style={{
                backgroundColor: initial?.color,
              }}
              onClick={() => setIsOpen(true)}
            >
              Keluar
            </div>
          </div>
          <div className="flex flex-col flex-gap">
            <div className="flex justify-center" onClick={() => handleClick()}>
              <img
                src={imageName ? `${imageName}` : user}
                className="img-size relative rounded-full object-cover saturate-50 z-[1] "
              />
              {/* <input
                disabled={isDisabled}
                type="file"
                ref={inputRef}
                accept="image/*"
                style={{ display: "none" }}
                // onChange={inputHandler}
                onChangeCapture={(event) => uploadChoosenPhoto(event)}
                placeholder="pilih foto"
              />
              <img
                src={camera}
                alt=""
                className="absolute z-[2] top-14 w-10 h-24"
              /> */}
            </div>
            <div className="mb-4">
              <label
                htmlFor="username"
                className="block text-gray-700 font-size"
              >
                Nama
              </label>
              <input
                type="text"
                id="name"
                className="border border-gray-300 rounded-full px-4 padding-form w-full font-size"
                value={name}
                disabled={isDisabled}
                onChange={(event) => setName(event.target.value)}
                // ref={usernameRef}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="birthDate"
                className="block text-gray-700 font-size"
              >
                Tanggal Lahir
              </label>
              <input
                type="date"
                id="birthDate"
                className="border border-gray-300 rounded-full px-4 padding-form w-full font-size"
                value={birthDate}
                disabled={isDisabled}
                onChange={(event) => setBirthDate(event.target.value)}

                // ref={usernameRef}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="birthPlace"
                className="block text-gray-700 font-size"
              >
                No Reg
              </label>
              <input
                type="text"
                id="birthPlace"
                className="border border-gray-300 rounded-full px-4 padding-form w-full font-size"
                value={noId}
                disabled={isDisabled}
                onChange={(event) => setNoId(event.target.value)}

                // ref={usernameRef}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="address"
                className="block text-gray-700 font-size"
              >
                Alamat
              </label>
              <input
                type="text"
                id="address"
                className="border border-gray-300 rounded-full px-4 padding-form w-full font-size"
                value={address}
                disabled={isDisabled}
                onChange={(event) => setAddress(event.target.value)}

                // ref={usernameRef}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="workPlace"
                className="block text-gray-700 font-size"
              >
                Lokasi Kerja
              </label>
              <select
                disabled={isDisabled}
                value={workLocation}
                onChange={(event) => {
                  setWorkLocation(event.target.value);
                }}
                className="border border-gray-300 rounded-full px-4 padding-form w-full"
              >
                <option></option>
                {locations.map((el: any, index: any) => (
                  <option className="font-size" key={index} value={el._id}>
                    {el.name}
                  </option>
                ))}
              </select>
            </div>
            {/* <button
              onClick={() => {
                isDisabled ? setDisabled(false) : updateProfile();
              }}
              className="button-padding bg-orange-600 mt-3 text-white font-medium text-sm items-center justify-center bg-yellow w-full flex flex-row rounded-lg gap-3 font-size"
            >
              {isDisabled ? "Edit" : "Simpan"}
            </button> */}
          </div>
        </div>
        <MenuBar label={"Profil"}></MenuBar>
      </LoadingOverlay>
    </div>
  );
};

export default Profile;

import React from "react";
import SecondaryHeader from "../../components/SecondaryHeader/SecondaryHeader";
import SelfieCheckinComponent from "../../components/SelfieCheckin/SelfieCheckin";

const SelfieCheckin: React.FC = () => {
  return (
    <div>
      <SecondaryHeader title="Absen" pathname="/home" />
      <SelfieCheckinComponent />
    </div>
  );
};

export default SelfieCheckin;

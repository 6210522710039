import React, { useState, useCallback, useRef, useEffect } from "react";
import QrScanner from "qr-scanner";
import {
  HttpLogin,
  HttpCheckToken,
  HttpPost,
  HttpGet,
} from "../../providers/HttpClient";
import Axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import MenuBar from "../MenuBar/MenuBar";
import Alert from "../alertComponent/alertComponent";
import { toast } from "react-toastify";

const ShiftChoose = () => {
  const navigate = useNavigate();
  const [shiftList, setShiftList] = useState<any>([]);
  const [shift, setShift] = useState("");
  const [address, setAddress] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [isGetAddress, setIsGetAddress] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const getShiftList = async () => {
    // setLoading(true);
    HttpGet(`member/combo/shift`)
      .then((res: any) => {
        setShiftList(res.data);
        // setLoading(false);
      })
      .catch((err) => {
        // setLoading(false);
      });
  };

  const getAddress = async (latitude, longitude) => {
    setLoading(true);
    Axios.post(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&api_key=659ceae1d022c624309563ymk7c7207`
    )
      .then((res: any) => {
        setAddress(res.data.display_name);
        setIsGetAddress(true);

        // setPowered(res.data.powered_by);
      })
      .catch((error) => {
        toast.error("Gagal mendapatkan lokasi");
        setIsGetAddress(false);
        navigate("/home");
      });
  };

  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current === false) {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
            getAddress(position.coords.latitude, position.coords.longitude);
          },
          function (error) {
            console.error("Error getting geolocation:", error);
          }
        );
      } else {
        console.log("Geolocation is not available in this browser.");
      }
      getShiftList();
      effectRan.current = true;
    }
  }, []);

  return (
    <div className="bg-main h-full mb-12">
      <div className="pb-28 ">
        <div className="px-5 py-24 mb-8">
          <label htmlFor="location" className="block text-gray-700">
            Pilihan Shift
          </label>
          <select
            value={shift}
            onChange={(event) => {
              setShift(event.target.value);
            }}
            className="bg-white border border-gray-300 rounded-full px-4 py-2 w-full"
          >
            <option disabled value={""}></option>
            {shiftList.map((el: any, index: any) => (
              <option key={index} value={el._id}>
                {el.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="bar fixed z-50 bottom-0 w-full bg-white px-4 py-5">
        {shift === "" ? (
          <button
            className={
              "text-center active:bg-zinc-100 rounded-md border border-transparent bg-green-100 px-5 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 w-full"
            }
          >
            {"Lanjutkan"}
          </button>
        ) : (
          <button
            className={
              "text-center active:bg-zinc-100 rounded-md border border-transparent bg-green-300 px-5 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 w-full"
            }
            onClick={() => {
              navigate(`/home/checkin/${shift}`);
            }}
          >
            {"Lanjutkan"}
          </button>
        )}
      </div>
    </div>
  );
};
export default ShiftChoose;

import React, { FC, useEffect, useState } from "react";
import "moment/locale/id";
import "./homePage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import clock from "../../assets/svg/clock.svg";
import patrol from "../../assets/svg/speaker.svg";
import BeforeAbsence from "../beforeAbsenceComponent/beforeAbsenceComponent";
import { get } from "http";
import { HttpGet } from "../../providers/HttpClient";

interface CheckMenuProps {
  isAbsence: boolean;
}
const Menus: FC<CheckMenuProps> = (props): JSX.Element => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [menus, setMenus] = useState<any[]>([]);
  const [companyProfile, setCompanyProfile] = useState<any>(
    JSON.parse(localStorage.getItem("companyProfile"))
  );

  const closeAction = async () => {
    setOpen(false);
  };

  useEffect(() => {
    HttpGet("member/menu").then((res: any) => {
      setMenus(res.data);
    });
  }, []);
  return (
    <>
      <BeforeAbsence isOpen={open} closeAction={closeAction} />
      <div className="flex justify-around rounded-2xl w-full bg-white p-3 shadow-md mb-4">
        {menus.map((prop, index) => {
          return (
            <div key={index} className="grid">
              <div
                onClick={() => {
                  if (prop.key === "OVERTIME") {
                    navigate("/home/overtimes-list");
                  } else {
                    props.isAbsence != null
                      ? navigate(
                          prop.key === "OVERTIME_BACKUP"
                            ? "/home/overtime-form"
                            : prop.key === "PATROL"
                            ? "/home/patrol-form"
                            : "/home/overtimes-list"
                        )
                      : setOpen(true);
                  }
                }}
                // style={prop.key != "OVERTIME_BACKUP" ? { height: 9 } : {}}
                className="w-fit rounded-md bg-white justify-self-center "
              >
                <FontAwesomeIcon
                  className=" text-slate-600"
                  size="lg"
                  // color={companyProfile.color}
                  icon={
                    prop.key === "OVERTIME"
                      ? "business-time"
                      : prop.key === "OVERTIME_BACKUP"
                      ? "user-clock"
                      : "bullhorn"
                  }
                />
                {/* <img
                  src={prop.key === "OVERTIME_BACKUP" ? clock : patrol}
                  alt="qr"
                  className="p-2 image-menu"
                /> */}
              </div>
              <p className="text-slate-700 text-center font-light text-xs w-14">
                {prop.readableName}
              </p>
            </div>
          );
        })}

        {/* <div>
          <div
            onClick={() => {
              props.isAbsence != null
                ? navigate("/home/patrol-form")
                : setOpen(true);
            }}
            className="w-fit rounded-md bg-white "
          >
            <img src={patrol} alt="qr" className="p-2 image-menu" />
          </div>
          <p className="text-black text-center font-light text-sm">Patroli</p>
        </div> */}
      </div>
    </>
  );
};

export default Menus;

import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { HttpLogin, HttpCheckToken, HttpGet } from "../../providers/HttpClient";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../assets/LOGO LUJ.png";
import EyeOffIcon from "../../assets/svg/eye-off.svg";
import EyeIcon from "../../assets/svg/eye.svg";
import SecondaryHeader from "../../components/SecondaryHeader/SecondaryHeader";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

// import "../../index.css";

const LoginPage: React.FC = () => {
  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [typePassword, setTypePassword] = useState("password");
  const param = useParams();
  const [companyProfile, setCompanyProfile] = useState<any>({});
  const [companyLogo, setCompanyLogo] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [bgColor, setBgColor] = useState("");

  const navigate = useNavigate();

  const Login = async () => {
    var username = usernameRef.current?.value;
    var password = passwordRef.current?.value;
    var data = {
      username: username,
      password: password,
    };

    HttpLogin(
      "member/account/login",
      `username=${companyProfile.initial}.${username}&password=${password}`
    ).then((res: any) => {
      localStorage.setItem("token", "Bearer " + res.data.access_token);
      localStorage.setItem("checkinInfo", null);
      HttpCheckToken(
        "member/account/check_token",
        `Bearer ${res.data.access_token}`
      ).then((resp: any) => {
        localStorage.setItem("tokenInfo", JSON.stringify(resp.data));
        toast.success("Berhasil Login");
        navigate("/home");
      });
    });
  };

  const funTypePassword = async () => {
    if (typePassword == "text") {
      setTypePassword("password");
    } else {
      setTypePassword("text");
    }
  };

  const GetCompanyProfile = () => {
    setIsLoading(true);
    HttpGet(`guest/company/${param.initial}`).then((res: any) => {
      setBgColor("black");
      setCompanyProfile(res.data);
      localStorage.setItem("companyProfile", JSON.stringify(res.data));
      setCompanyLogo(
        "https://lujgroup-staging.sgp1.digitaloceanspaces.com/member/660f30f834f4e51a6128d8ae/660f30f834f4e51a6128d8af_6.png"
      );
      setIsLoading(false);
    });
  };
  useEffect(() => {
    GetCompanyProfile();
  }, []);
  return (
    <section className="">
      <SecondaryHeader />
      {isLoading ? (
        <Skeleton
          containerClassName="flex justify-center mt-24"
          baseColor="#d6d4d4"
          highlightColor="#e8e6e6"
          className="h-36 w-36 mr-4 absolute gray rounded-full"
        />
      ) : (
        <div className="flex justify-center mt-24">
          <img
            src={companyProfile?.logo}
            alt="Logo"
            className="h-36 w-36 mr-4 absolute rounded-full"
          />
        </div>
      )}

      <div
        style={{
          height: 670,
          marginTop: 98,
          backgroundColor: companyProfile?.color,
        }}
        className={`flex justify-center items-start rounded-t-2xl `}
      >
        <div className="p-8">
          <h1 className="font-bold text-2xl text-white">Login</h1>
          <p className="mb-4 text-white">
            Silahkan masukkan username dan password anda
          </p>
          <form>
            <div className="mb-4">
              <label htmlFor="username" className="block text-white">
                Username:
              </label>
              {isLoading ? (
                <Skeleton
                  containerClassName="mb-4"
                  baseColor="#d6d4d4"
                  highlightColor="#e8e6e6"
                  className="border border-gray rounded-full px-4 py-2 w-full"
                />
              ) : (
                <input
                  type="text"
                  id="username"
                  className="border border-gray-300 rounded-full px-4 py-2 w-full"
                  ref={usernameRef}
                />
              )}
            </div>

            <div className="mb-4">
              <label htmlFor="password" className="block text-white">
                Password:
              </label>
              {isLoading ? (
                <Skeleton
                  containerClassName="mb-4"
                  baseColor="#d6d4d4"
                  highlightColor="#e8e6e6"
                  className="border border-gray rounded-full px-4 py-2 w-full"
                />
              ) : (
                <div className="relative w-full flex">
                  <input
                    type={typePassword}
                    id="password"
                    className="border border-gray-300 rounded-full px-4 py-2 w-full"
                    ref={passwordRef}
                  />
                  <img
                    src={typePassword != "text" ? EyeOffIcon : EyeIcon}
                    className="font-bold text-black absolute z-10 top-2 right-5"
                    onClick={() => funTypePassword()}
                  />
                </div>
              )}
            </div>
            {isLoading ? (
              <Skeleton
                containerClassName="justify-center flex mt-8"
                baseColor="#d6d4d4"
                highlightColor="#e8e6e6"
                className="bg-gray  px-4 rounded-full w-3/6 text-center"
              />
            ) : (
              <div className="justify-center flex mt-8">
                <div
                  onClick={() => Login()}
                  style={{ color: companyProfile?.color }}
                  className="bg-white hover:bg-gray-200 font-bold py-2 px-4 rounded-full w-3/6 text-center"
                >
                  Masuk
                </div>
              </div>
            )}
          </form>
          <div className="mt-36">
            <div className="flex justify-center bottom-0">
              {" "}
              <p className="font-bold text-base text-white">
                powered by:{" "}
              </p>{" "}
            </div>
            <div className="flex justify-center bottom-0">
              {" "}
              <p className="font-bold text-base text-white">LUJGROUP </p>{" "}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;

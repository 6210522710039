import { toast } from "react-toastify";
import { error400, error401, error404 } from "./interceptor_error_message.js";

let message;
function isJson(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}
const ErrorHandler = (error) => {
  if (error.code === "ECONNABORTED") {
    //timout error
    message = "Batas waktu habis. Silahkan coba lagi.";
  } else if (error.response) {
    if (error.response.status == 401) {
      message = error401(error.response.data.detail);
      localStorage.clear();
      window.location.replace("/");
    } else if (error.response.status == 400) {
      message = error400(error.response.data.detail);
    } else if (error.response.status == 404) {
      message = error404(error.response.data.detail);
    } else if (error.response.status == 403) {
      message = error.response.data.detail;
    } else if (error.response.status == 422) {
      message = error.response.data.detail;
    } else if (error.response.status == 500) {
      message = "Internal Server Error";
    } else {
      message = error.response.data.detail;
    }
  } else if (error.request) {
    message = "Koneksi Gagal";
  } else {
    message = "Koneksi Gagal";
  }
  toast.error(message);
};

export default ErrorHandler;

import React, { useEffect, useRef, useState } from "react";
import SecondaryHeader from "../SecondaryHeader/SecondaryHeader";
import { HttpGet, HttpPost, HttpPostFile } from "../../providers/HttpClient";
import LoadingOverlay from "react-loading-overlay-ts";
import { useLocation, useNavigate } from "react-router-dom";
import "react-spring-bottom-sheet/dist/style.css";
import camera from "../../assets/svg/camera.svg";
import add from "../../assets/svg/add.svg";
import remove from "../../assets/svg/remove.svg";
import Alert from "../alertComponent/alertComponent";
import Config from "../../config/Config";
import MenuBar from "../MenuBar/MenuBar";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Axios from "axios";

const Patrol: React.FC = () => {
  const env = Config.BaseUrlDev;
  const navigate = useNavigate();
  const [hours, setHours] = useState<any>(8);
  const nameOfMemberBackedUp = useRef<HTMLInputElement>(null);
  const noteRef = useRef<HTMLTextAreaElement>(null);
  const [isLoading, setLoading] = useState(false);
  const [dataUri, setDataUri] = useState("");
  const [dataUri2, setDataUriTwo] = useState("");
  const [dataUri3, setDataUriThree] = useState("");
  const [openCam, setOpenCam] = useState(false);
  const [takePhoto, setTakePhoto] = useState(false);
  const [photoTo, setPhotoTo] = useState("");
  const [onDismissBotto, setOnDismiss] = useState(false);
  const inputFile = useRef(null);
  const [imageFile1, setImageFile1] = useState(null);
  const [imageFile2, setImageFile2] = useState(null);
  const [imageFile3, setImageFile3] = useState(null);
  const [imageName1, setImageName1] = useState("");
  const [imageName2, setImageName2] = useState("");
  const [imageName3, setImageName3] = useState("");
  const [imageUrl1, setImageUrl1] = useState("");
  const [imageUrl2, setImageUrl2] = useState("");
  const [imageUrl3, setImageUrl3] = useState("");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const inputRef2 = useRef<HTMLInputElement | null>(null);
  const inputRef3 = useRef<HTMLInputElement | null>(null);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState(false);
  const [loadingImage1, setLoadingImage1] = useState(false);
  const [loadingImage2, setLoadingImage2] = useState(false);
  const [loadingImage3, setLoadingImage3] = useState(false);
  const [addImage, setAddImage] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [isGetAddress, setIsGetAddress] = useState(false);
  const [address, setAddress] = useState("");
  const [status, setStatus] = useState(
    JSON.parse(localStorage.getItem("checkinInfo"))
  );

  const alert = (message, type) => {
    setOpen(true);
    setAlertMessage(message);
    setAlertType(type);
  };
  const closeAction = async () => {
    setOpen(false);
    navigate("/home");
  };

  const openBottomSheet = () => {
    setOpenCam(true);
    setPhotoTo("1");
  };

  const openBottomSheet2 = () => {
    setOpenCam(true);
    setPhotoTo("2");
  };

  const openBottomSheet3 = () => {
    setOpenCam(true);
    setPhotoTo("3");
  };

  const openCamSheet = () => {
    setTakePhoto(true);
  };

  const closeBottomSheet = () => {
    setOpenCam(false);
  };

  const closeCamSheet = () => {
    setTakePhoto(false);
  };

  const convertPhoto = (string64: string, fileName: string) => {
    const trimmedString = string64.replace("data:image/png;base64,", "");
    const imageContent = atob(trimmedString);
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);

    for (let n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
    const type = "image/jpeg";
    const blob = new Blob([buffer], { type });
    return new File([blob], fileName, {
      lastModified: new Date().getTime(),
      type,
    });
  };

  const uploadPhoto = async (imageFile: any) => {
    let formData = new FormData();
    formData.append("image", imageFile);

    HttpPostFile(`member/images?feature=patrol`, formData).then((res: any) => {
      if (imageFile.name === "foto1") return setImageName1(res.data.imageName);
      if (imageFile.name === "foto2") return setImageName2(res.data.imageName);
      if (imageFile.name === "foto3") return setImageName3(res.data.imageName);
    });
  };

  const uploadChoosenPhoto = async (event) => {
    let formData = new FormData();
    formData.append("image", event.target.files[0]);

    if (photoTo === "1") {
      setLoadingImage1(true);
    } else if (photoTo === "2") {
      setLoadingImage2(true);
    } else if (photoTo === "3") {
      setLoadingImage3(true);
    }
    HttpPostFile(`member/images?feature=patrol`, formData).then((res: any) => {
      if (photoTo === "1")
        return (
          setImageName1(res.data),
          setImageUrl1(res.data),
          setLoadingImage1(false)
        );
      if (photoTo === "2")
        return (
          setImageName2(res.data),
          setImageUrl2(res.data),
          setLoadingImage2(false)
        );
      if (photoTo === "3")
        return (
          setImageName3(res.data),
          setImageUrl3(res.data),
          setLoadingImage3(false)
        );
    });
  };

  const validate = (value) => {
    if (value.areaOnePicture == "")
      return alert("Foto Area 1 Harap Diisi", false);
    if (value.areaTwoPicture == "")
      return alert("Foto Area 2 Harap Diisi", false);
    if (value.areaThreePicture == "")
      return alert("Foto Area 3 Harap Diisi", false);
    return;
  };

  const managePatrol = async () => {
    // alert("Selamat", "success");
    // return;
    if (imageName1 == "") return toast.error("Foto Area 1 Harap Diisi");
    if (imageName2 == "") return toast.error("Foto Area 2 Harap Diisi");
    // if (imageName3 == "") return toast.error("Foto Area 3 Harap Diisi");
    if (noteRef.current?.value == "")
      return toast.error("Keterangan Harap Diisi");

    let data = {
      areaOnePicture: imageName1,
      areaTwoPicture: imageName2,
      areaThreePicture: imageName3,
      note: noteRef.current?.value,
      location: {
        lat: latitude,
        long: longitude,
        address: address,
      },
      // shiftId: status.shiftId,
    };

    setLoading(true);
    HttpPost(`member/patrol`, data)
      .then((res) => {
        alert("Laporan Patroli berhasil dikirim", true);
        setLoading(false);
        // navigate("/home");
      })
      .catch((err) => {
        alert("Gagal mengirim laporan patroli", false);
        setLoading(false);
      });
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  function handleTakePhotoAnimationDone(dataUri: any) {
    setDataUri(dataUri);
  }

  const setDataUri1 = (dataUri) => {
    setDataUri(dataUri);

    uploadPhoto(convertPhoto(dataUri, "foto1"));
  };

  const setDataUri2 = (dataUri) => {
    setDataUriTwo(dataUri);
    uploadPhoto(convertPhoto(dataUri, "foto2"));
  };

  const setDataUri3 = (dataUri) => {
    setDataUriThree(dataUri);
    uploadPhoto(convertPhoto(dataUri, "foto3"));
  };

  const handleClick = () => {
    inputRef?.current?.click();
    setPhotoTo("1");
  };

  const handleClick2 = () => {
    inputRef2?.current?.click();
    setPhotoTo("2");
  };

  const handleClick3 = () => {
    inputRef3?.current?.click();
    setPhotoTo("3");
  };

  const getAddress = async (latitude, longitude) => {
    setLoading(true);
    Axios.get(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&api_key=659ceae1d022c624309563ymk7c7207`
    )
      .then((res: any) => {
        setAddress(res.data.display_name);
        setIsGetAddress(true);
        setLoading(false);
        // setPowered(res.data.powered_by);
      })
      .catch((error) => {
        setIsGetAddress(true);
      });
  };
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          getAddress(position.coords.latitude, position.coords.longitude);
        },
        function (error) {
          console.error("Error getting geolocation:", error);
        }
      );
    } else {
      console.log("Geolocation is not available in this browser.");
    }
  }, []);
  return (
    <div className="min-h-screen h-full bg-main">
      <Alert
        type={alertType}
        isOpen={open}
        closeAction={closeAction}
        message={alertMessage}
      />
      <LoadingOverlay
        className="h-full"
        active={isLoading}
        spinner
        text="Loading..."
      >
        {/* <SecondaryHeader title="Patroli" pathname="/home" /> */}

        <section id="main" className="px-8 pb-28 z-50">
          <div className="z-100 z-30 mb-4">
            <p className="text-lg font-bold">Laporan Patroli</p>
            <div className="mb-4 mt-2">
              <label htmlFor="overtime" className="block text-gray-700">
                Foto Area 1
              </label>
              <div hidden={loadingImage1 == false}>
                <Skeleton
                  containerClassName="border-2 border-gray-400 border-dashed flex justify-center relative bg-white rounded-2xl"
                  baseColor="#000000"
                  highlightColor="#363434"
                  className="w-full h-36 relative rounded-xl object-cover saturate-50 z-[1]"
                  style={{ borderRadius: 12 }}
                />
              </div>
              {loadingImage1 == false ? (
                <div
                  // hidden={loadingImage1 == true}
                  className="border-2 border-gray-400 border-dashed flex justify-center relative bg-white rounded-2xl"
                  onClick={() => handleClick()}
                >
                  <img
                    // hidden={loadingImage1 == true}
                    src={imageUrl1 ? `${imageName1}` : null}
                    className="w-full h-36 relative rounded-xl object-cover saturate-50 z-[1]"
                  />
                  <input
                    type="file"
                    ref={inputRef}
                    accept="image/*"
                    capture
                    style={{ display: "none" }}
                    id="photo"
                    name="photo"
                    // onChange={inputHandler}
                    onChangeCapture={(event) => uploadChoosenPhoto(event)}
                    placeholder="pilih foto"
                  />
                  <img
                    // hidden={loadingImage1 == true}
                    src={camera}
                    alt=""
                    className="absolute z-[2] top-14 w-10 h-10"
                  />
                </div>
              ) : null}
            </div>

            <div className="mb-4">
              <label htmlFor="overtime" className="block text-gray-700">
                Foto Area 2
              </label>
              <div hidden={loadingImage2 == false}>
                <Skeleton
                  containerClassName="border-2 border-gray-400 border-dashed flex justify-center relative bg-white rounded-2xl"
                  baseColor="#000000"
                  highlightColor="#363434"
                  className="w-full h-36 relative rounded-xl object-cover saturate-50 z-[1]"
                  style={{ borderRadius: 12 }}
                />
              </div>
              {loadingImage2 == false ? (
                <div
                  // hidden={loadingImage2 == true}
                  className="border-2 border-gray-400 border-dashed flex justify-center relative bg-white rounded-2xl"
                  onClick={() => handleClick2()}
                >
                  <img
                    // hidden={loadingImage2 == true}
                    src={imageUrl2 ? `${imageName2}` : null}
                    className="w-full h-36 relative rounded-xl object-cover saturate-50 z-[1] "
                  />
                  <input
                    type="file"
                    ref={inputRef2}
                    accept="image/*"
                    capture
                    style={{ display: "none" }}
                    id="photo"
                    name="photo"
                    // onChange={inputHandler}
                    onChangeCapture={(event) => uploadChoosenPhoto(event)}
                    placeholder="pilih foto"
                  />
                  <img
                    // hidden={loadingImage2 == true}
                    src={camera}
                    alt=""
                    className="absolute z-[2] top-14 w-10 h-10"
                  />
                </div>
              ) : null}
            </div>
            <div>
              <div
                className="flex"
                onClick={() => {
                  setAddImage(!addImage);
                }}
              >
                <img
                  src={addImage ? remove : add}
                  alt="add"
                  className="w-8 h-8"
                />
                <p className="flex items-center z-10 top-2 right-5 text-sm">
                  Tambah gambar
                </p>
              </div>
            </div>
            <div hidden={!addImage}>
              <label htmlFor="overtime" className="block text-gray-700">
                Foto Area 3
              </label>
              <div hidden={loadingImage3 == false}>
                <Skeleton
                  containerClassName="border-2 border-gray-400 border-dashed flex justify-center relative bg-white rounded-2xl"
                  baseColor="#000000"
                  highlightColor="#363434"
                  className="w-full h-36 relative rounded-xl object-cover saturate-50 z-[1]"
                  style={{ borderRadius: 12 }}
                />
              </div>
              {loadingImage3 == false ? (
                <div
                  // hidden={loadingImage3 == true}
                  className="border-2 border-gray-400 border-dashed flex justify-center relative bg-white rounded-2xl"
                  onClick={() => handleClick3()}
                >
                  <img
                    // hidden={loadingImage3 == true}
                    src={imageUrl3 ? `${imageName3}` : null}
                    className="w-full h-36 relative rounded-xl object-cover saturate-50 z-[1]"
                  />
                  <input
                    type="file"
                    ref={inputRef3}
                    accept="image/*"
                    capture
                    style={{ display: "none" }}
                    id="photo"
                    name="photo"
                    // onChange={inputHandler}
                    onChangeCapture={(event) => uploadChoosenPhoto(event)}
                    placeholder="pilih foto"
                  />
                  <img
                    // hidden={loadingImage3 == true}
                    src={camera}
                    alt=""
                    className="absolute z-[2] top-14 w-10 h-10"
                  />
                </div>
              ) : null}
            </div>
            <div className="mb-4 mt-4">
              <label htmlFor="note" className="block text-gray-700">
                Lokasi
              </label>
              <input
                disabled
                id="address"
                className="border border-gray-300 rounded-lg px-4 py-2 w-full"
                value={address}
              />
            </div>
            <div className="mb-4 mt-4">
              <label htmlFor="note" className="block text-gray-700">
                Keterangan
              </label>
              <textarea
                id="note"
                className="border border-gray-300 rounded-lg px-4 py-2 w-full"
                ref={noteRef}
              />
            </div>
          </div>
          <div className="flex justify-end">
            <div
              onClick={() => managePatrol()}
              className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full justify-center grid w-28"
            >
              Laporkan
            </div>
          </div>
        </section>
        <MenuBar></MenuBar>
      </LoadingOverlay>
    </div>
  );
};

export default Patrol;

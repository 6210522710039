import React, { useState, useCallback, useRef, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import MenuBar from "../MenuBar/MenuBar";
import "./QRscanner.css";
import Axios from "axios";
// import Skeleton from "react-loading-skeleton";
// import "react-loading-skeleton/dist/skeleton.css";
import { Camera } from "react-camera-pro";
import { HttpGet, HttpPost, HttpPostFile } from "../../providers/HttpClient";
import Alert from "../alertComponent/alertComponent";
import spinner from "../../assets/svg/spinner.svg";
import { toast } from "react-toastify";

const SelfieCheckin = () => {
  let location: any = useLocation();
  const navigate = useNavigate();
  const camera = useRef(null);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState(false);
  const [image, setImage] = useState(null);
  const [shift, setShift] = useState("");
  const [openCamera, setOpenCamera] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [shiftList, setShiftList] = useState<any>([]);
  const [isGetAddress, setIsGetAddress] = useState(false);
  const [address, setAddress] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const alert = (message, type, subMessage) => {
    setOpen(true);
    setAlertMessage(message);
    setAlertType(type);
  };

  const closeAction = async () => {
    setOpen(false);
    navigate("/home");
  };

  const getShiftList = async () => {
    setLoading(true);
    HttpGet(`member/combo/shift`)
      .then((res: any) => {
        setShiftList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getAddress = async (latitude, longitude) => {
    setLoading(true);
    Axios.get(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
    )
      .then((res: any) => {
        setAddress(res.data.display_name);
        setIsGetAddress(true);
        setLoading(false);
        // setPowered(res.data.powered_by);
      })
      .catch((error) => {
        toast.error("Gagal mendapatkan lokasi");
        setIsGetAddress(false);
        navigate("/home");
      });
  };

  const convertBase64ToFile = (base64String) => {
    const byteString = atob(base64String.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ia], { type: "image/png" }); // Buat Blob dari data mentah
    const file = new File([blob], "image.png", { type: "image/png" }); // Buat File dari Blob

    return file;
  };

  const checkIn = (image) => {
    let file = convertBase64ToFile(image);

    let formData = new FormData();
    formData.append("image", file);
    setLoading(true);
    HttpPostFile(`member/images?feature=checkin`, formData).then((res: any) => {
      // alert("Absensi Berhasil", true, 'aaaaa');

      let datas = {
        checkInPhoto: res.data,
        location: {
          lat: latitude,
          lon: longitude,
          address: address,
        },
      };
      HttpPost(`member/attendance_by_photo/${shift}`, datas)
        .then((resp: any) => {
          alert("Absensi Berhasil", true, "");
          setLoading(false);
        })
        .catch((err) => {
          alert("Absensi Gagal", false, "");
        });
    });
  };

  const effectRan = useRef(false);
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          getAddress(position.coords.latitude, position.coords.longitude);
        },
        function (error) {
          console.error("Error getting geolocation:", error);
        }
      );
    } else {
      console.log("Geolocation is not available in this browser.");
    }
    getShiftList();
    effectRan.current = true;
  }, []);
  return (
    <>
      <div className="bg-main h-full mb-12">
        <Alert
          type={alertType}
          isOpen={open}
          closeAction={closeAction}
          message={alertMessage}
          subMessage={""}
        />
        {openCamera ? null : (
          <div className="px-5 py-24 mb-8">
            <label htmlFor="location" className="block text-gray-700">
              Pilihan Shift
            </label>
            <select
              value={shift}
              onChange={(event) => {
                setShift(event.target.value);
              }}
              className="bg-white border border-gray-300 rounded-full px-4 py-2 w-full"
            >
              <option disabled value={""}></option>
              {shiftList.map((el: any, index: any) => (
                <option key={index} value={el._id}>
                  {el.name}
                </option>
              ))}
            </select>
          </div>
        )}
        {openCamera ? (
          <>
            <div className="camera-container">
              <div className="camera-video">
                <Camera
                  facingMode="user"
                  aspectRatio={"cover"}
                  ref={camera}
                  errorMessages={{
                    noCameraAccessible: "no camera",
                    permissionDenied: "denied",
                    switchCamera: "switch",
                    canvas: "aaa",
                  }}
                />
              </div>
            </div>
          </>
        ) : null}
        <div className="bar fixed z-50 bottom-0 w-full bg-white px-4 py-5">
          {!shift ? (
            <button
              className={
                "text-center active:bg-zinc-100 rounded-md border border-transparent bg-green-100 px-5 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 w-full"
              }
            >
              {"Lanjutkan"}
            </button>
          ) : (
            <button
              disabled={isLoading}
              onClick={() => {
                !openCamera
                  ? setOpenCamera(true)
                  : checkIn(camera.current.takePhoto());
              }}
              className={
                isLoading
                  ? "text-center active:bg-zinc-100 rounded-md border border-transparent bg-green-200 px-5 py-2 text-sm font-medium  w-full"
                  : "text-center active:bg-zinc-100 rounded-md border border-transparent bg-green-300 px-5 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 w-full"
              }
            >
              <div className="flex items-center justify-center">
                <img
                  hidden={!isLoading}
                  src={spinner}
                  alt=""
                  className="animate-spin h-5 w-5 mr-3 "
                />
                {!openCamera ? "Lanjutkan" : "Ambil Gambar"}
              </div>
            </button>
          )}
        </div>
        {/* <MenuBar label={""}></MenuBar> */}
      </div>
    </>
  );
};
export default SelfieCheckin;

import React from "react";
import SecondaryHeader from "../../components/SecondaryHeader/SecondaryHeader";
import CheckoutComponent from "../../components/CheckoutComponent/CheckOut";

const Profile: React.FC = () => {
  return (
    <div>
      <SecondaryHeader title="Absen" pathname="/home" />
      <CheckoutComponent />
    </div>
  );
};

export default Profile;

import Axios from "axios";
import Config from "../config/Config";
import ErrorHandler from "./errorHandler";
// setupInterceptorsTo(Axios);

let apiUrl = Config.BaseUrlDev;

const timeout = 60000;
const httpClient = Axios.create();
httpClient.defaults.timeout = timeout;
const HttpLogin = (url: any, data: any) => {
  return new Promise((resolve, reject) => {
    Axios.post(apiUrl + url, data, {
      headers: {
        accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        ErrorHandler(error);
        reject();
      });
  });
};

const HttpPost = (url: any, data: any) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(apiUrl + url, data, {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        let token = res.headers.authorization;
        if (!res.headers.authorization) {
          token = `${localStorage.getItem("token")}`;
        }

        if (!token.includes("Bearer")) {
          token = "Bearer " + token;
        }
        localStorage.setItem("token", token);

        resolve(res);
      })
      .catch((error) => {
        ErrorHandler(error);
        reject();
      });
  });
};

const HttpPostFile = (url: any, data: any) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(apiUrl + url, data, {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        let token = res.headers.authorization;
        if (!res.headers.authorization) {
          token = `${localStorage.getItem("token")}`;
        }

        if (!token.includes("Bearer")) {
          token = "Bearer " + token;
        }
        localStorage.setItem("token", token);

        resolve(res);
      })
      .catch((error) => {
        ErrorHandler(error);
        reject();
      });
  });
};

const HttpGet = (url: any) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(apiUrl + url, {
        headers: {
          authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        let token = res.headers.authorization;
        if (!res.headers.authorization) {
          token = `${localStorage.getItem("token")}`;
        }
        if (!token.includes("Bearer")) {
          token = "Bearer " + token;
        }
        localStorage.setItem("token", token);
        resolve(res);
      })
      .catch((error) => {
        ErrorHandler(error);
        reject();
      });
  });
};

const HttpCheckToken = (url: any, token: any) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(apiUrl + url, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        let token = res.headers.authorization;
        if (!res.headers.authorization) {
          token = `${localStorage.getItem("token")}`;
        }
        if (!token.includes("Bearer")) {
          token = "Bearer " + token;
        }
        localStorage.setItem("token", token);
        resolve(res);
      })
      .catch((error) => {
        ErrorHandler(error);
        reject();
      });
  });
};

const HttpPut = (url: any, data: any) => {
  return new Promise((resolve, reject) => {
    httpClient
      .put(apiUrl + url, data, {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        let token = res.headers.authorization;
        if (!res.headers.authorization) {
          token = `${localStorage.getItem("token")}`;
        }
        if (!token.includes("Bearer")) {
          token = "Bearer " + token;
        }
        localStorage.setItem("token", token);

        resolve(res);
      })
      .catch((error) => {
        ErrorHandler(error);
        reject();
      });
  });
};

const HttpDelete = (url: any) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(apiUrl + url, {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        let token = res.headers.authorization;
        if (!res.headers.authorization) {
          token = `${localStorage.getItem("token")}`;
        }
        if (!token.includes("Bearer")) {
          token = "Bearer " + token;
        }
        localStorage.setItem("token", token);

        resolve(res);
      })
      .catch((error) => {
        ErrorHandler(error);
        reject();
      });
  });
};

export {
  HttpLogin,
  HttpPost,
  HttpPut,
  HttpDelete,
  HttpGet,
  HttpCheckToken,
  HttpPostFile,
};

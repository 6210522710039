import React, { useState, Fragment, useEffect } from "react";
import user from "../../assets/1.jpg";
import qrScan from "../../assets/svg/qrScan.svg";
import clock from "../../assets/svg/clock.svg";
import patrol from "../../assets/svg/speaker.svg";
import checkout from "../../assets/svg/checkout.svg";
import checkin from "../../assets/svg/checkin.svg";
import checklistIn from "../../assets/svg/checklistIn.svg";
import checklistOut from "../../assets/svg/checklistOut.svg";
import MenuBar from "../MenuBar/MenuBar";
import { useLocation, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import BeforeAbsence from "../beforeAbsenceComponent/beforeAbsenceComponent";
import { HttpCheckToken, HttpGet, HttpPut } from "../../providers/HttpClient";
import { logRoles } from "@testing-library/react";
import Config from "../../config/Config";
import moment from "moment";
import "moment/locale/id";
import "./homePage.css";
import "./CheckMenu";
import CheckMenu from "./CheckMenu";
import Menus from "./Menus";
import Events from "./Events";
import History from "./History";

moment.locale("id");
const HomePage: React.FC = () => {
  const env = Config.BaseUrlDev;
  const navigate = useNavigate();
  const [status, setStatus] = useState(
    JSON.parse(localStorage.getItem("checkinInfo"))
  );
  const [tokenInfo, setTokenInfo] = useState(
    JSON.parse(localStorage.getItem("tokenInfo"))
  );
  const [isAbsence, setIsAbsence] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [history, setHistory] = useState<any>([]);
  const [profilePicture, setProfilePicture] = useState("");
  const [name, setName] = useState("");
  const [isOvertime, setIsOvertime] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [events, setEvents] = useState<any>([]);
  const [today, setToday] = useState<any>("");
  const [checkOutTime, setCheckOutTime] = useState<any>();
  const [todayCheckOut, setTodayCheckOut] = useState<any>(new Date());
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [realCheckOutTime, setRealCheckOutTime] = useState<any>();
  const [companyProfile, setCompanyProfile] = useState<any>(
    JSON.parse(localStorage.getItem("companyProfile"))
  );

  const closeActionLogout = async () => {
    setIsOpen(false);
  };
  const closeAction = async () => {
    setOpen(false);
  };
  const getCheckOutTime = async (value) => {
    let checkOutTime = value[value.length - 1].shift.checkOutTime;

    const today = new Date();

    const [hours, minutes, seconds] = checkOutTime.split(":").map(Number);

    const result = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      hours,
      minutes,
      seconds
    );

    const sixtyMinutesBefore = new Date(result.getTime() - 60 * 60 * 1000);

    setCheckOutTime(sixtyMinutesBefore);
    setRealCheckOutTime(value[value.length - 1].shift.checkOutTime);
  };
  const checkToken = async () => {
    HttpCheckToken(
      "member/account/check_token",
      `${localStorage.getItem("token")}`
    ).then((res: any) => {
      localStorage.setItem("tokenInfo", JSON.stringify(res.data));
      setIsAbsence(
        res.data.currentShiftId === null
          ? res.data.currentShiftIdAttendanceByPhoto
          : res.data.currentShiftId
      );
      setIsOvertime(res.data.isOvertime);
      setIsFirstLogin(res.data.isFirstLogin);
    });
  };
  const getProfile = async () => {
    HttpGet(`member/profile`)
      .then((res: any) => {
        setProfilePicture(res.data.profilePicture);
        setName(res.data.name);
      })
      .catch((err) => {});
  };
  const checkOut = () => {
    HttpPut(`member/attendance/check_out`, "")
      .then((res: any) => {
        localStorage.setItem("checkinInfo", JSON.stringify(res.data));
        checkToken();
        setIsOpen(false);
        navigate("/home");
      })
      .catch((err) => {});
  };

  const getHistoryToday = () => {
    HttpGet(`member/attendance/today`)
      .then((res: any) => {
        setHistory(res.data.content);
        getCheckOutTime(res.data.content);
      })
      .catch((err) => {});
  };

  const getEvent = () => {
    HttpGet(`member/event?size=10&page=0&sort=updateTime&dir=1`)
      .then((res: any) => {
        setEvents(res.data.content);
      })
      .catch((err) => {});
  };

  const getDate = () => {
    // Buat objek Date untuk tanggal hari ini
    const today = new Date();

    // Temukan hari ini dalam bentuk angka (0 = Minggu, 1 = Senin, dst.)
    const dayOfWeek = today.getDay();

    // Hitung selisih hari untuk mendapatkan awal minggu ini (Minggu sebagai awal minggu)
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - dayOfWeek);

    // Hitung selisih hari untuk mendapatkan akhir minggu ini (Sabtu sebagai akhir minggu)
    const endOfWeek = new Date(today);
    endOfWeek.setDate(today.getDate() + (6 - dayOfWeek));

    // Buat objek opsi untuk format tanggal yang diinginkan
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const formattedStartOfWeek = startOfWeek;
    const formattedEndOfWeek = endOfWeek;

    setStartDate(moment(formattedStartOfWeek).format("L"));
    setEndDate(moment(formattedEndOfWeek).format("L"));
  };
  useEffect(() => {
    // getCheckOutTime();
    getHistoryToday();
    checkToken();
    getProfile();
    getEvent();
    getDate();

    setToday(moment(new Date()).format("DD/MM/YYYY"));
  }, [isAbsence]);
  return (
    <div className="bg-main h-full">
      <Transition appear show={isFirstLogin} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeAction}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 flex justify-center"
                  >
                    Silahkan Mengubah Password Anda
                  </Dialog.Title>

                  <div className="mt-4 flex justify-center gap-2">
                    <button
                      type="button"
                      className="active:bg-psp-hover active:text-white inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => {
                        navigate("/home/change-first-password");
                      }}
                    >
                      Ubah Password
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeActionLogout}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                  >
                    Check Out
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500 text-center">
                      Apakah anda yakin?
                    </p>
                  </div>

                  <div className="mt-4 flex gap-2 justify-center">
                    <button
                      type="button"
                      className="active:bg-psp-hover active:text-white inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => checkOut()}
                    >
                      Ya
                    </button>
                    <button
                      type="button"
                      className="active:bg-zinc-100 inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={() => closeActionLogout()}
                    >
                      Tidak
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <BeforeAbsence isOpen={open} closeAction={closeAction} />
      <section id="main" className="-mt-28 z-50 pb-24">
        <div className="mt-28 z-100 z-30 mb-4">
          <div
            style={{ backgroundColor: companyProfile?.color }}
            className="rounded-b-3xl w-full  p-3 shadow-lg mb-5 mt-0"
          >
            <div className="flex gap-2 mx-8 mb-3 mt-0">
              <img
                src={profilePicture ? `${profilePicture}` : user}
                alt="User"
                className="image-profile relative rounded-full object-cover saturate-50 z-[1] mr-2"
              />
              <div className="">
                <p className="text-white font-bold font-size">{name}</p>
                {isAbsence && !isOvertime ? (
                  <div className="rounded-full bg-green-400 w-fit">
                    <p className="p-2 text-white font-bold font-label">
                      Anda Sedang Bertugas
                    </p>
                  </div>
                ) : isOvertime ? (
                  <div className="rounded-full bg-green-400 w-fit">
                    <p className="p-2 text-white font-bold font-label">
                      Anda Sedang Lembur
                    </p>
                  </div>
                ) : (
                  <div className="rounded-full w-fit bg-orange-600">
                    <p className="p-2 text-white font-bold font-label">
                      Anda Tidak Bertugas
                    </p>
                  </div>
                )}
              </div>
            </div>
            <CheckMenu isAbsence={isAbsence} />
          </div>

          <div className="px-5">
            <div className="mb-8 mt-3">
              <Menus isAbsence={isAbsence} />
              <Events isAbsence={isAbsence} />
            </div>
            <History isAbsence={isAbsence} />
          </div>
        </div>
      </section>
      <MenuBar label={"Beranda"}></MenuBar>
    </div>
  );
};

export default HomePage;

import React, { FC, useEffect, useState } from "react";
import "moment/locale/id";
import "./homePage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import checkout from "../../assets/svg/checkout.svg";
import checkin from "../../assets/svg/checkin.svg";
import checklistIn from "../../assets/svg/checklistIn.svg";
import checklistOut from "../../assets/svg/checklistOut.svg";
import BeforeAbsence from "../beforeAbsenceComponent/beforeAbsenceComponent";
import moment from "moment";
import { HttpGet } from "../../providers/HttpClient";

interface CheckMenuProps {
  isAbsence: boolean;
}
const History: FC<CheckMenuProps> = (props): JSX.Element => {
  const navigate = useNavigate();
  const [history, setHistory] = useState<any>([]);
  const [checkOutTime, setCheckOutTime] = useState<any>();
  const [realCheckOutTime, setRealCheckOutTime] = useState<any>();
  const [userCategory, setuUserCategory] = useState(
    JSON.parse(localStorage.getItem("tokenInfo")).category
  );
  const [attendanceType, setAttendanceType] = useState<any>(
    JSON.parse(localStorage.getItem("companyProfile")).attendanceType
  );

  const getCheckOutTime = async (value) => {
    let checkOutTime = value[value.length - 1].shift.checkOutTime;

    const today = new Date();

    const [hours, minutes, seconds] = checkOutTime.split(":").map(Number);

    const result = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      hours,
      minutes,
      seconds
    );

    const sixtyMinutesBefore = new Date(result.getTime() - 60 * 60 * 1000);

    setCheckOutTime(sixtyMinutesBefore);
    setRealCheckOutTime(value[value.length - 1].shift.checkOutTime);
  };

  const getHistoryToday = () => {
    if (attendanceType === "SELFIE") {
      HttpGet(`member/attendance_by_photo/today`)
        .then((res: any) => {
          setHistory(res.data.content);
          getCheckOutTime(res.data.content);
        })
        .catch((err) => {});
    } else {
      HttpGet(`member/attendance/today`)
        .then((res: any) => {
          setHistory(res.data.content);
          getCheckOutTime(res.data.content);
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    getHistoryToday();
  }, [props.isAbsence]);

  return (
    <>
      <p className="mb-2 font-header text-sm">Riwayat Absen Hari Ini</p>
      {history.map((prop, index) => {
        return (
          <>
            <div className="rounded-2xl w-full bg-white p-3 shadow-md mb-4">
              <div className="my-3 flex flex-row gap-3">
                <img src={checkin} alt="qr" className="p-2 icons" />
                <div className="flex justify-between w-full gap-2">
                  <div>
                    <p className="font-bold text-base">Absen Masuk</p>
                    <div className="flex gap-3 justify-between gap-6">
                      <p className="text-xs">Dijadwalkan</p>
                      <p className="text-xs font-bold text-green-500">
                        {prop.shift?.checkInTime}
                      </p>
                    </div>
                    <div className="flex gap-3 justify-between gap-6">
                      <p className="text-xs">Absen Masuk</p>
                      <p className="text-xs font-bold text-green-500">
                        {moment(prop.checkInTime).format("LTS")}
                      </p>
                    </div>
                  </div>
                  <img src={checklistIn} alt="qr" className="p-2 icons" />
                </div>
              </div>
            </div>
            <div className="rounded-2xl w-full bg-white p-3 shadow-md mb-4">
              <div className="my-3 flex flex-row gap-3">
                {/* <div className="max-h-9 my-auto rounded-full bg-orange-500"> */}
                <img src={checkout} alt="qr" className="icons p-2" />
                {/* </div> */}
                <div className="flex justify-between w-full gap-2">
                  <div>
                    <p className="font-bold text-base font-header">
                      Absen Pulang
                    </p>
                    <div className="flex gap-3 justify-between gap-6">
                      <p className="text-xs">Dijadwalkan</p>
                      <p className="text-xs font-bold text-green-500">
                        {prop.shift?.checkOutTime}
                      </p>
                    </div>
                    <div className="flex gap-3 justify-between gap-6">
                      <p className="text-xs">Absen Pulang</p>
                      <p className="text-xs font-bold text-green-500">
                        {prop.checkOutTime
                          ? moment(prop.checkOutTime).format("LTS")
                          : "-"}
                      </p>
                    </div>
                  </div>
                  <img src={checklistOut} alt="qr" className="icons p-2" />
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

export default History;

import React, { FC, useState } from "react";
import "moment/locale/id";
import "./homePage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

interface CheckMenuProps {
  isAbsence: boolean;
}
const CheckMenu: FC<CheckMenuProps> = (props): JSX.Element => {
  const navigate = useNavigate();
  const [userCategory, setuUserCategory] = useState(
    JSON.parse(localStorage.getItem("tokenInfo")).category
  );
  const [attendanceType, setAttendanceType] = useState<any>(
    JSON.parse(localStorage.getItem("companyProfile")).attendanceType
  );

  return (
    <div className="flex justify-between mx-8 my-3">
      <div
        // navigate("/home/selfie-checkin")
        onClick={() => {
          attendanceType === "SELFIE"
            ? navigate("/home/selfie-checkin")
            : navigate("/home/shift-choose");
        }}
        className={
          // props.isAbsence
          //   ? "bg-gray-400 rounded-md border border-transparent px-5 py-2 text-sm font-medium text-red-900 hover:bg-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
          //   :
          "active:bg-zinc-100 rounded-md border border-transparent bg-white px-5 py-2 text-sm font-medium text-red-900 hover:bg-white-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
        }
      >
        <FontAwesomeIcon className="mr-2 " icon="right-to-bracket" /> Check In
      </div>
      <div
        onClick={() => {
          props.isAbsence && attendanceType === "SELFIE"
            ? navigate("/home/selfie-checkout")
            : props.isAbsence && navigate("/home/checkout");
          // : "";
        }}
        className={
          !props.isAbsence
            ? "bg-gray-100 rounded-md border border-transparent px-5 py-2 text-sm font-medium text-red-900 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
            : "active:bg-zinc-100 rounded-md border border-transparent bg-white px-5 py-2 text-sm font-medium text-red-900 hover:bg-red-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
        }
      >
        <FontAwesomeIcon
          className="mr-2 rotate-180"
          icon="right-from-bracket"
        />{" "}
        Check Out
      </div>
    </div>
  );
};

export default CheckMenu;

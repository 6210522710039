import React from "react";
import SecondaryHeader from "../../components/SecondaryHeader/SecondaryHeader";
import ShiftChooseComponent from "../../components/QRScanComponent/ShiftChoose";
const ShiftChoose: React.FC = () => {
  return (
    <div>
      <SecondaryHeader title="Absen" pathname="/home" />
      <ShiftChooseComponent />
    </div>
  );
};

export default ShiftChoose;

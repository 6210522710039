import React, { FC, useEffect, useState } from "react";
import "moment/locale/id";
import "./homePage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import clock from "../../assets/svg/clock.svg";
import patrol from "../../assets/svg/speaker.svg";
import BeforeAbsence from "../beforeAbsenceComponent/beforeAbsenceComponent";
import moment from "moment";
import { HttpGet } from "../../providers/HttpClient";

interface CheckMenuProps {
  isAbsence: boolean;
}
const Events: FC<CheckMenuProps> = (props): JSX.Element => {
  const navigate = useNavigate();
  const [events, setEvents] = useState<any>([]);

  const getEvent = () => {
    HttpGet(`member/event?size=10&page=0&sort=updateTime&dir=1`)
      .then((res: any) => {
        setEvents(res.data.content);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getEvent();
  }, [props.isAbsence]);

  return (
    <>
      {events.map((prop, index) => {
        const eventStartTime = moment(prop.eventDatetime).subtract(24, "hours");
        const eventEndTime = moment(prop.eventDatetime).add(2, "hours");
        const currentTime = moment();

        if (currentTime >= eventStartTime && currentTime <= eventEndTime) {
          return (
            <div
              key={index}
              className="rounded-2xl w-full bg-white p-3 shadow-md mb-4"
            >
              <div className="py-1">
                <p className="font-bold text-base text-red-500">Pengumuman</p>
                <p className="text-xs">
                  {moment(prop.eventDatetime).format(
                    "dddd, DD/MM/YYYY hh:mm:ss"
                  )}
                </p>
                <p className="text-xs">{prop.name}</p>
              </div>
              <div className="flex justify-end">
                <div
                  className="bg-green-400 px-3 py-2 text-white text-xs font-medium rounded-full"
                  onClick={() => navigate(`/home/event/${prop._id}`)}
                >
                  Konfirmasi Kehadiran
                </div>
              </div>
            </div>
          );
        }
      })}
    </>
  );
};

export default Events;

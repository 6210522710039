import React, { useEffect, useRef, useState, useContext } from "react";
import SecondaryHeader from "../SecondaryHeader/SecondaryHeader";
import MenuBar from "../MenuBar/MenuBar";
import user from "../../assets/1.jpg";
import { HttpGet, HttpPost } from "../../providers/HttpClient";
import LoadingOverlay from "react-loading-overlay-ts";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Alert from "../alertComponent/alertComponent";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const Overtime2: React.FC = () => {
  const navigate = useNavigate();
  const [hours, setHours] = useState<any>(8);
  const noteRef = useRef<HTMLTextAreaElement>(null);
  const [isLoading, setLoading] = useState(false);
  const [status, setStatus] = useState(
    JSON.parse(localStorage.getItem("checkinInfo"))
  );
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState(false);
  const [date, setDate] = useState("");
  const [beforeShift, setBeforeShift] = useState<any>(null);
  const [afterShift, setAfterShift] = useState<any>(null);
  const [shift, setShift] = useState<any>("");
  const [shiftList, setShiftList] = useState<any>([]);

  const alert = (message, type) => {
    setOpen(true);
    setAlertMessage(message);
    setAlertType(type);
  };
  const closeAction = async () => {
    setOpen(false);
    navigate("/home");
  };
  const manageOvertime = async () => {
    let data = {
      note: noteRef.current?.value,
      extensionDate: date,
      durationBeforeShiftMinutes: beforeShift,
      durationAfterShiftMinutes: afterShift,
      shiftId: shift,
    };
    setLoading(true);
    HttpPost(`member/work_extension`, data)
      .then((res) => {
        alert("Lembur Telah Dikirim !", true);
        setLoading(false);
        // navigate("/home");
      })
      .catch((err) => {
        setLoading(false);
        alert("Lembur Gagal Dikirim !", false);
      });
  };

  const getShiftList = async () => {
    setLoading(true);
    HttpGet(`member/combo/shift`)
      .then((res: any) => {
        setShiftList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getShiftList();
  }, []);
  return (
    <>
      <Alert
        type={alertType}
        isOpen={open}
        closeAction={closeAction}
        message={alertMessage}
      />
      <LoadingOverlay
        className="h-full"
        active={isLoading}
        spinner
        text="Loading..."
      >
        <header className="flex items-center justify-between px-4 py-1">
          <h1 className="text-base font-bold">Lembur</h1>
        </header>
        {/* <SecondaryHeader title="Lembur" pathname="/home" /> */}
        <section id="main" className="bg-main h-full px-8 pb-24 -mt-28 z-50">
          <div className="mt-32 z-100 z-30 mb-4">
            <div className="mb-4">
              <label htmlFor="date" className="block text-gray-700">
                Tanggal Lembur
              </label>
              <input
                type="date"
                value={date}
                onChange={(event) => {
                  setDate(event.target.value);
                }}
                className="bg-white border border-gray-300 rounded-full px-4 py-2 w-full"
              ></input>
            </div>
            <div className="mb-4">
              <label htmlFor="location" className="block text-gray-700">
                Pilihan Shift
              </label>
              <select
                value={shift}
                onChange={(event) => {
                  setShift(event.target.value);
                }}
                className="bg-white border border-gray-300 rounded-full px-4 py-2 w-full"
              >
                <option disabled value={""}></option>
                {shiftList.map((el: any, index: any) => (
                  <option key={index} value={el._id}>
                    {el.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label htmlFor="beforeShift" className="block text-gray-700">
                Durasi Sebelum Shift
              </label>
              <div className="relative w-full flex">
                <input
                  type="number"
                  value={beforeShift}
                  onChange={(event) => {
                    setBeforeShift(event.target.value);
                  }}
                  className="bg-white border border-gray-300 rounded-full px-4 py-2 w-full"
                />
                <p className="font-light text-gray-500 absolute z-10 top-2 right-5">
                  Menit
                </p>
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="afterShift" className="block text-gray-700">
                Durasi Setelah Shift
              </label>
              <div className="relative w-full flex">
                <input
                  type="number"
                  value={afterShift}
                  onChange={(event) => {
                    setAfterShift(event.target.value);
                  }}
                  className="bg-white border border-gray-300 rounded-full px-4 py-2 w-full"
                />
                <p className="font-light text-gray-500 absolute z-10 top-2 right-5">
                  Menit
                </p>
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="note" className="block text-gray-700">
                Keterangan
              </label>
              <textarea
                id="note"
                className="border border-gray-300 rounded-lg px-4 py-2 w-full"
                ref={noteRef}
              />
            </div>
          </div>
          <div className="flex justify-end">
            <div
              onClick={() => manageOvertime()}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full justify-center grid w-28"
            >
              Kirim
            </div>
          </div>
        </section>
        <MenuBar></MenuBar>
      </LoadingOverlay>
    </>
  );
};

export default Overtime2;

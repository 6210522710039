import React, { useEffect, useRef, useState, useContext } from "react";
import SecondaryHeader from "../SecondaryHeader/SecondaryHeader";
import MenuBar from "../MenuBar/MenuBar";
import user from "../../assets/1.jpg";
import { HttpGet, HttpPost } from "../../providers/HttpClient";
import LoadingOverlay from "react-loading-overlay-ts";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Alert from "../alertComponent/alertComponent";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const OvertimeList: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [datas, setDatas] = useState<any>([]);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const [startDate, setStartDate] = useState(
    new Date(currentYear, currentMonth, 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(currentYear, currentMonth, 1)
  );

  const getOvertimeList = async (startDate, endDate) => {
    let startExtensionDate = moment(startDate).format("YYYY-MM-DD");
    let endExtensionDate =
      // startDate.getMonth() === endDate.getMonth()
      // ?
      moment(endDate).add(29, "days").format("YYYY-MM-DD");
    // : moment(endDate).format("YYYY-MM-DD");
    setLoading(true);

    HttpGet(
      `member/work_extension?startExtensionDate=${startExtensionDate}&endExtensionDate=${endExtensionDate}&sort=extensionDate&dir=-1`
    )
      .then((res: any) => {
        setDatas(res.data.content);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleChange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  useEffect(() => {
    if (startDate && endDate) {
      getOvertimeList(startDate, endDate);
    }
  }, [startDate, endDate]);

  return (
    <>
      <div className="bg-main h-full">
        <div className="z-100 z-30 mb-4 pb-24 px-1">
          <p className="py-8 text-center font-header font-bold">Lembur</p>

          <div className="flex justify-center mb-4">
            <DatePicker
              className="border-2 rounded-md"
              startDate={startDate}
              endDate={endDate}
              onChange={handleChange}
              dateFormat="MM/yyyy"
              placeholderText="Pilih Tanggal"
              showMonthYearPicker
              withPortal
              selectsRange
              showIcon
              // icon="calendar"
            />
          </div>
          {datas.length
            ? datas.map((prop: any, index: any) => {
                return (
                  <div className="rounded-2xl w-full bg-main p-3 pb-0 ">
                    <div>
                      <p className="font-normal text-sm ml-3 mb-1">
                        Request Lembur
                      </p>
                      <p className="font-normal text-xs ml-3 text-slate-500">
                        {moment(prop.extensionDate).format("DD MMM YYYY")}
                      </p>
                      <p
                        className={`font-normal text-xs ml-3 ${
                          prop.status === "SUCCESS"
                            ? "text-green-500"
                            : "text-slate-500"
                        }`}
                      >
                        {prop.status.toLowerCase()}
                      </p>
                    </div>
                    {/* <div className="ml-auto ">
                      <FontAwesomeIcon
                        className="text-slate-500"
                        icon="chevron-right"
                      />
                    </div> */}
                    <hr className="mt-2" />
                  </div>
                );
              })
            : null}
          <div className="bar fixed z-50 bottom-0 w-full bg-white px-4 py-5">
            <button
              className={
                "text-center active:bg-zinc-100 rounded-md border border-transparent bg-green-300 px-5 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 w-full"
              }
              onClick={() => {
                navigate(`/home/overtimes-form`);
              }}
            >
              {"Ajukan Lembur"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default OvertimeList;
